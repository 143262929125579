import { Paper, Stack, Typography } from "@mui/material";
import { memo } from "react";
import PersonIcon from "@mui/icons-material/Person";
import { ClienteListaFirmaInterface } from "../../interfaces/ClienteListaFirmaInterface";

interface Props {
  cliente: ClienteListaFirmaInterface;
  onClick: (cliente: ClienteListaFirmaInterface) => void;
}

function ClienteListItem(props: Props) {
  const { cliente, onClick } = props;

  return (
    <Paper
      onClick={() => {
        onClick(cliente);
      }}
      variant="outlined"
      sx={{ p: 2, cursor: "pointer" }}
    >
      <Stack flexDirection="row" alignItems="center" gap={2}>
        <PersonIcon fontSize="large" color="primary" />
        <Stack flexDirection="column" spacing={1}>
          <Typography sx={{ fontWeight: "bold" }}>
            {cliente.Cognome} {cliente.Nome}
          </Typography>
          <Typography>{cliente.Indirizzo}</Typography>
          <Typography>
            {cliente.Cap} {cliente.Citta} ({cliente.Provincia})
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
}

export default memo(ClienteListItem);
