export function estraiParametroX(url: string) {
  // Creazione di un oggetto URL per analizzare l'URL fornito
  const urlObj = new URL(url);

  // Utilizzo di URLSearchParams per ottenere il valore del parametro 'x'
  const params = new URLSearchParams(urlObj.search);
  const parametroX = params.get("x");

  // Controllo se il parametro 'x' esiste ed è stato estratto correttamente
  if (parametroX) {
    return parametroX;
  } else {
    // Se il parametro 'x' non esiste, restituire un messaggio o un valore predefinito
    return "";
  }
}

export function estraiUrlBase(url: string) {
  // Creazione di un oggetto URL per analizzare l'URL fornito
  const urlObj = new URL(url);
  return urlObj.origin;
}

export const regexFOCUSBLUDATACOM = /https:\/\/focus\.bludata\.com\/appclienti\?x=[0-9a-z-A-Z]+/;
export const regexDATAXIMO = /https:\/\/data[0-9][0-9]\.ilmioottico\.it\/appclienti\?x=[0-9a-z-A-Z]+/;