import { Button, Stack, Typography } from "@mui/material";
import { memo } from "react";
import linguaPrivacy from "../../static/linguaPrivacy.json";

interface Props {
  consensoValue: boolean | undefined;
  text: string;
  show: boolean;
  language: string;
  onConsensoChange: (consenso: boolean) => void;
}

function RichiestaConsensoBlock(props: Props) {
  const { consensoValue, text, show, language, onConsensoChange } = props;

  if (!show) return <></>;

  let statoSI: "text" | "outlined" | "contained" = "outlined";
  let statoNO: "text" | "outlined" | "contained" = "outlined";

  if (consensoValue) statoSI = "contained";
  if (consensoValue === false) statoNO = "contained";
  const { languageEnglish, languageItalian, languageGerman } = linguaPrivacy;

  let linguaggio = languageItalian;

  if (language === "EN") {
    linguaggio = languageEnglish;
  }
  if (language === "DE") {
    linguaggio = languageGerman;
  }

  return (
    <Stack flexDirection="column" spacing={1}>
      <Typography
        component="div"
        sx={{
          "& p": {
            marginBlockStart: "3px",
            marginBlockEnd: "3px",
          },
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </Typography>
      <Stack flexDirection="row" gap={1}>
        <Button
          onClick={() => {
            onConsensoChange(true);
          }}
          disableElevation
          variant={statoSI}
          sx={{ justifyContent: "center" }}
        >
          {linguaggio.GDPR.tastoSi}
        </Button>
        <Button
          onClick={() => {
            onConsensoChange(false);
          }}
          disableElevation
          variant={statoNO}
          sx={{ justifyContent: "center" }}
        >
          {linguaggio.GDPR.tastoNo}
        </Button>
      </Stack>
    </Stack>
  );
}

export default memo(RichiestaConsensoBlock);
