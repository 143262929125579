import { memo } from "react";
import AppBackground from "../UI/AppBackground";
import { Stack, Typography } from "@mui/material";
import BludataContainerBlock from "../UI/BludataContainerBlock";
import logo from "../images/home-icon.jpg";

interface Props {
  errorText: string;
}

function ErrorLock(props: Props) {
  const { errorText } = props;

  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", height: "100%", position: "relative" }}
    >
      <AppBackground />
      <BludataContainerBlock sx={{ zIndex: 99 }}>
        <Stack
          flexDirection="column"
          alignItems="center"
          spacing={3}
          sx={{ p: 5 }}
        >
          <img
            style={{
              boxShadow:
                "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
              width: 120,
              borderRadius: "10px",
            }}
            src={logo}
            alt="logo"
          />
          <Typography>{errorText}</Typography>
        </Stack>
      </BludataContainerBlock>
    </Stack>
  );
}

export default memo(ErrorLock);
