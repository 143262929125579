import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import BludataContainerBlock from "../../UI/BludataContainerBlock";
import { Controller, useFormContext } from "react-hook-form";
import prov from "../../static/prov.json";
import stati from "../../static/stati.json";
import linguaPrivacy from "../../static/linguaPrivacy.json";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useEffect } from "react";

function IndirizzoFatturazione() {
  const { control, watch, register, setValue } = useFormContext();
  const stato = watch("Stato2");
  const { linguaImpostata } = useSelector((state: RootState) => state.root);
  const { languageEnglish, languageItalian, languageGerman } = linguaPrivacy;

  let linguaggio = languageItalian;

  if (linguaImpostata === "EN") {
    linguaggio = languageEnglish;
  }
  if (linguaImpostata === "DE") {
    linguaggio = languageGerman;
  }

  useEffect(() => {
    register("Provincia2");
    register("Stato2", {
      value: "IT",
    });
  }, [register]);

  return (
    <BludataContainerBlock>
      <Stack flexDirection="column" spacing={1}>
        <Typography sx={{ fontWeight: "bold" }}>
          {linguaggio.sottotitoli.titoloIndFatt2}
        </Typography>
        <Stack flexDirection="row" gap={1}>
          <Controller
            control={control}
            name="indirizzo2"
            render={({ field, formState: { errors } }) => (
              <TextField
                sx={{ flex: 1 }}
                autoComplete="off"
                label={linguaggio.inputField.indirizzo}
                inputProps={{ maxlenght: 40 }}
                {...field}
              />
            )}
          />
        </Stack>

        <Stack flexDirection="row" gap={1}>
          <Controller
            control={control}
            name="citta2"
            render={({ field, formState: { errors } }) => (
              <TextField
                sx={{ flex: 1 }}
                autoComplete="off"
                label={linguaggio.inputField.citta}
                inputProps={{ maxlenght: 45 }}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name={stato === "IT" ? "CAP2" : "CAP2_straniero"}
            render={({ field, formState: { errors } }) => (
              <TextField
                sx={{ width: 100 }}
                type={stato === "IT" ? "number" : "text"}
                inputProps={{ maxlenght: stato === "IT" ? 5 : 9 }}
                autoComplete="off"
                label={linguaggio.inputField.cap}
                {...field}
              />
            )}
          />
          {stato === "IT" && (
            <Autocomplete
              options={prov.map((p) => {
                return { ...p, label: `${p.codice} - ${p.nome}` };
              })}
              sx={{ width: 160 }}
              onChange={(e, newValue) => {
                setValue("Provincia2", newValue?.codice || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={linguaggio.inputField.provincia}
                />
              )}
            />
          )}
          {stato !== "IT" && (
            <Controller
              control={control}
              name="Provincia2_straniera"
              render={({ field, formState: { errors } }) => (
                <TextField
                  sx={{ width: 150 }}
                  autoComplete="off"
                  label={linguaggio.inputField.provincia}
                  inputProps={{ maxlenght: 50 }}
                  {...field}
                />
              )}
            />
          )}
          <Autocomplete
            options={stati.map((p) => {
              return { ...p, label: `${p.stato} - ${p.name}` };
            })}
            disableClearable
            defaultValue={stati
              .map((p) => {
                return { ...p, label: `${p.stato} - ${p.name}` };
              })
              .find((s) => s.stato === "IT")}
            sx={{ width: 140 }}
            onChange={(e, newValue) => {
              setValue("Stato2", newValue?.stato);
            }}
            renderInput={(params) => (
              <TextField {...params} label={linguaggio.inputField.stato} />
            )}
          />
        </Stack>
      </Stack>
    </BludataContainerBlock>
  );
}

export default IndirizzoFatturazione;
