import { Button, Stack, Typography } from "@mui/material";
import AppBackground from "../UI/AppBackground";
import BludataContainerBlock from "../UI/BludataContainerBlock";
import homeicon from "../images/home-icon.jpg";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { useState } from "react";
import ScannerizzazioneQR from "./qr/ScannerizzazioneQR";

function BluSignAttivazioneCamera() {
  const [attivaPressed, setAttivaPressed] = useState(false);

  const onAttivaBluSignClick = () => {
    setAttivaPressed(true);
  };

  const onAnnullaAttivazioneClick = () => {
    setAttivaPressed(false);
  };

  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", height: "100%", position: "relative" }}
    >
      <AppBackground />
      <BludataContainerBlock sx={{ zIndex: 99 }}>
        <Stack
          flexDirection="column"
          alignItems="center"
          spacing={3}
          sx={{ p: 5 }}
        >
          <img
            style={{
              width: 120,
              borderRadius: "10px",
              boxShadow:
                "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
            }}
            src={homeicon}
            alt="logo"
          />
          {!attivaPressed && (
            <>
              <Typography>
                Per utilizzare Blu Sign è necessario procedere all'attivazione.
              </Typography>
              <Stack flexDirection="column">
                <Typography>
                  Clicca il tasto Attiva Blu Sign e inquadra il codice QR di
                  attivazione che trovi in FOCUS 10.
                </Typography>
                <Typography fontSize="small" component="i">
                  Sarà necessario abilitare l'accesso alla fotocamera per poter
                  continuare.
                </Typography>
              </Stack>
              <Button
                variant="outlined"
                disableElevation
                startIcon={<QrCodeScannerIcon />}
                onClick={onAttivaBluSignClick}
              >
                Attiva Blu Sign
              </Button>
            </>
          )}
          {attivaPressed && (
            <ScannerizzazioneQR
              annullaAttivazione={onAnnullaAttivazioneClick}
            />
          )}
        </Stack>
      </BludataContainerBlock>
    </Stack>
  );
}

export default BluSignAttivazioneCamera;
