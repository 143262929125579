import { memo } from "react";
import ErrorLock from "./ErrorLock";

function DispositivoNonCompatibile() {
  return (
    <ErrorLock
      errorText="Il dispositivo che stai utilizzando non è compatibile con questa
    applicazione."
    />
  );
}

export default memo(DispositivoNonCompatibile);
