import { useSelector } from "react-redux";
import { RootState } from "../store";
import MainView from "./MainView";
import FormInserimento from "./form/FormInserimento";
import { Stack } from "@mui/material";
import ListaFirmaClienti from "./ListaFirmaClienti";
import RichiestaFirma from "./RichiestaFirma";

function AppLayout() {
  const { selectedView } = useSelector((state: RootState) => state.root);

  return (
    <Stack sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
      {selectedView === "home" && <MainView />}
      {selectedView === "forminserimento" && <FormInserimento />}
      {selectedView === "listaclientifirma" && <ListaFirmaClienti />}
      {selectedView === "richiestafirma" && <RichiestaFirma />}
    </Stack>
  );
}

export default AppLayout;
