import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import BludataContainerBlock from "../../UI/BludataContainerBlock";
import titoli from "../../static/titoli.json";
import { nanoid } from "@reduxjs/toolkit";
import { Controller, useFormContext } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import linguaPrivacy from "../../static/linguaPrivacy.json";
import "dayjs/locale/it";
import "dayjs/locale/en";
import "dayjs/locale/de";
import dayjs, { Dayjs } from "dayjs";

function DatiAnagrafici() {
  const { control, watch, setValue } = useFormContext();
  const sesso = watch("sesso");
  const titolo = watch("titolo");
  const { linguaImpostata } = useSelector((state: RootState) => state.root);
  const { languageEnglish, languageItalian, languageGerman } = linguaPrivacy;

  let linguaggio = languageItalian;

  if (linguaImpostata === "EN") {
    linguaggio = languageEnglish;
  }

  if (linguaImpostata === "DE") {
    linguaggio = languageGerman;
  }

  useEffect(() => {
    const titoloAttuale = titoli.find((t) => t.testo === titolo);
    setValue("sesso", titoloAttuale?.sesso || sesso);
    //eslint-disable-next-line
  }, [titolo, setValue]);

  useEffect(() => {
    const titoloAttuale = titoli.find((t) => t.testo === titolo);
    const newTitolo = titoli.find(
      (t) => t.testoNeutro === titoloAttuale?.testoNeutro && t.sesso === sesso
    );

    if (newTitolo) {
      setValue("titolo", newTitolo.testo);
    }
    //eslint-disable-next-line
  }, [sesso, setValue]);

  return (
    <BludataContainerBlock>
      <Stack flexDirection="column" spacing={1}>
        <Typography sx={{ fontWeight: "bold" }}>
          {linguaggio.sottotitoli.titoloAnag}
        </Typography>
        <Stack flexDirection="row" gap={1}>
          <Controller
            control={control}
            name="titolo"
            render={({ field, formState: { errors } }) => (
              <FormControl sx={{ flex: 1 }}>
                <InputLabel>{linguaggio.inputField.titolo}</InputLabel>
                <Select label={linguaggio.inputField.titolo} {...field}>
                  {titoli.map((titolo) => (
                    <MenuItem value={titolo.testo} key={nanoid()}>
                      {titolo.testo}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="cognome"
            render={({ field, formState: { errors } }) => (
              <TextField
                inputProps={{ maxlenght: 30 }}
                sx={{ flex: 1 }}
                autoComplete="off"
                error={!!errors[field.name]}
                label={linguaggio.inputField.cognome}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="nome"
            render={({ field, formState: { errors } }) => (
              <TextField
                sx={{ flex: 1 }}
                autoComplete="off"
                error={!!errors[field.name]}
                label={linguaggio.inputField.nome}
                inputProps={{ maxlenght: 20 }}
                {...field}
              />
            )}
          />
        </Stack>

        <Stack flexDirection="row" alignItems={"center"} gap={1}>
          <Controller
            control={control}
            name="sesso"
            render={({ field, formState: { errors } }) => (
              <FormControl>
                <FormLabel>{linguaggio.sottotitoli.titoloSesso}</FormLabel>
                <RadioGroup {...field} row>
                  <FormControlLabel
                    value="M"
                    control={<Radio />}
                    label={linguaggio.inputField.sessoUomo}
                  />
                  <FormControlLabel
                    value="F"
                    control={<Radio />}
                    label={linguaggio.inputField.sessoDonna}
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="dataNascita"
            render={({ field, formState: { errors } }) => (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={linguaImpostata.toLowerCase()}
              >
                <DesktopDatePicker
                  label={linguaggio.inputField.dataN}
                  maxDate={dayjs().subtract(1, "days")}
                  sx={{
                    flex: 1,
                    maxWidth: 140,
                    "& .MuiInput-root:after": !!errors[field.name]
                      ? {
                          transform: "scaleX(1) translateX(0) !important",
                          borderBottom: "2px solid",
                          borderBottomColor: "#bc3c44 !important",
                        }
                      : undefined,
                  }}
                  {...field}
                  onChange={(e: Dayjs | null) => {
                    field.onChange(e?.format("YYYY-MM-DDTHH:mm:ss"));
                  }}
                />
              </LocalizationProvider>
            )}
          />
          <Controller
            control={control}
            name="luogoNascita"
            render={({ field, formState: { errors } }) => (
              <TextField
                sx={{ flex: 1 }}
                autoComplete="off"
                error={!!errors[field.name]}
                label={linguaggio.inputField.luogoNascita}
                inputProps={{ maxlenght: 45 }}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="codiceFiscale"
            render={({ field, formState: { errors } }) => (
              <TextField
                sx={{ flex: 1 }}
                error={!!errors[field.name]}
                label={linguaggio.inputField.codiceFiscale}
                autoComplete="off"
                inputProps={{ maxlenght: 16 }}
                {...field}
                onChange={(e: any) => {
                  e.target.value = e.target.value?.toUpperCase() || "";
                  field.onChange(e);
                }}
              />
            )}
          />
        </Stack>
      </Stack>
    </BludataContainerBlock>
  );
}

export default DatiAnagrafici;
