import { object, string } from "yup";
import { defaultFormValues } from "../views/form/FormInserimento";
import getCampoObbligatorio from "./getCampoObbligatorio";

export const getFormSchema = (campiObbligatori: string) => {
  let temp: any = {};

  console.log('campiObbligatori',campiObbligatori)

  for (const key in defaultFormValues) {
    if (Object.prototype.hasOwnProperty.call(defaultFormValues, key)) {
      if (key === "Email") {
        temp[key] = getCampoObbligatorio(key, campiObbligatori)
          ? string().email().required("Campo obbligatorio.")
          : string().email();
      } else if(key==='dataNascita'){
        temp[key] = getCampoObbligatorio(key, campiObbligatori)
          ? string().required("Campo obbligatorio.")
          : string().nullable();
      } else {
        temp[key] = getCampoObbligatorio(key, campiObbligatori)
          ? string().required("Campo obbligatorio.")
          : string();
      }
    }
  }

  return object().shape(temp);
};
