import { Button, Stack, Typography } from "@mui/material";
import AppBackground from "../UI/AppBackground";
import BludataContainerBlock from "../UI/BludataContainerBlock";
import { getIdCode } from "../App";
import FooterBludata from "../UI/FooterBludata";
import PersonAddAltTwoToneIcon from "@mui/icons-material/PersonAddAltTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import {
  setClienteFirma,
  setListaFirmaClienti,
  setSelectedView,
} from "../store/slices/RootSlice";
import { useState } from "react";
import BludataLoaderFullscreen from "../UI/BludataLoaderFullscreen";
import axios from "axios";
import { BluAsyncDialog } from "@bludata/components";
import { ClienteListaFirmaInterface } from "../interfaces/ClienteListaFirmaInterface";
import logo from "../images/home-icon.jpg";

export const fetchListaClienti = () =>
  new Promise<ClienteListaFirmaInterface[]>((resolve, reject) => {
    axios
      .get<{
        data: ClienteListaFirmaInterface[];
      }>("/getlistclifirma", {
        params: {
          idCode: getIdCode(),
        },
      })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch(() => {
        reject();
      });
  });

function MainView() {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);

  const inserisciAnagraficaTap = () => {
    dispatch(setSelectedView("forminserimento"));
  };

  const inserisciFirmaTap = () => {
    setLoading(true);

    fetchListaClienti()
      .then((response) => {
        if (response.length === 0) {
          BluAsyncDialog({
            title: "Attenzione",
            message:
              "FOCUS non ha reso disponibile alcun cliente per la raccolta della firma.<br/><br/>Entra in FOCUS ed invia le schede dei clienti di cui devi raccogliere la firma.",
            hideDeclineButton: true,
            confimButton: "Chiudi",
            type: "info",
          });
        } else {
          if (response.length === 1) {
            dispatch(setClienteFirma(response[0]));
            dispatch(setSelectedView("richiestafirma"));
          } else {
            dispatch(setListaFirmaClienti(response));
            dispatch(setSelectedView("listaclientifirma"));
          }
        }
      })
      .catch(() => {
        BluAsyncDialog({
          title: "Attenzione",
          message: "Errore di connessione al server, riprovare più tardi.",
          hideDeclineButton: true,
          confimButton: "Chiudi",
          type: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", height: "100%", position: "relative" }}
    >
      <AppBackground />
      <FooterBludata />
      {loading && <BludataLoaderFullscreen open />}
      <BludataContainerBlock
        sx={{ zIndex: 99, backgroundColor: "rgba(255,255,255,0.7)" }}
      >
        <Stack
          flexDirection="column"
          alignItems="center"
          spacing={3}
          sx={{ p: 1 }}
        >
          <img
            style={{
              width: 120,
              marginTop: 20,
              borderRadius: "10px",
              boxShadow:
                "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
            }}
            src={logo}
            alt="logo"
          />
          <Stack flexDirection="row" gap={1}>
            <Button
              variant="outlined"
              onClick={inserisciAnagraficaTap}
              sx={{ p: 3, flex: 1, backgroundColor: "white" }}
            >
              <Stack
                flexDirection="column"
                alignItems="center"
                spacing={2}
                sx={{ width: "100%" }}
              >
                <PersonAddAltTwoToneIcon
                  color="primary"
                  sx={{ fontSize: 50 }}
                />
                <Typography sx={{ color: "var(--base-color)", fontSize: 13 }}>
                  INSERISCI L'ANAGRAFICA DI UN CLIENTE
                </Typography>
              </Stack>
            </Button>
            <Button
              variant="outlined"
              onClick={inserisciFirmaTap}
              sx={{ p: 2, flex: 1, backgroundColor: "white" }}
            >
              <Stack
                flexDirection="column"
                alignItems="center"
                spacing={2}
                sx={{ width: "100%" }}
              >
                <EditTwoToneIcon color="primary" sx={{ fontSize: 50 }} />
                <Typography sx={{ color: "var(--base-color)", fontSize: 13 }}>
                  RICHIEDI LA FIRMA AD UN CLIENTE
                </Typography>
              </Stack>
            </Button>
          </Stack>
        </Stack>
      </BludataContainerBlock>
    </Stack>
  );
}

export default MainView;
