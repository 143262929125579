import { Box, Stack, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import logo_bludata_50 from "../images/logo_bludata_50.png";
import packageJson from "../../package.json";
import { clearIpadBluSign, isIlMioOttico } from "../App";
import { BluAsyncDialog } from "@bludata/components";

function FooterBludata() {
  const [clickCount, setClickCount] = useState(0);
  const [clickTime, setClickTime] = useState<any>(null);

  const resetCodes = async () => {
    if (
      await BluAsyncDialog({
        title: "Attenzione",
        message: "Vuoi resettare l'app Blu Sign e inquadrare un nuovo QR code?",
        type: "warning",
      })
    ) {
      clearIpadBluSign();
      window.location.reload();
    }
  };

  useEffect(() => {
    if (clickCount === 1) {
      // Imposta il timer al primo click
      const timer = setTimeout(() => {
        setClickCount(0); // Resetta il conteggio dopo 1 secondo
      }, 1000);
      return () => clearTimeout(timer);
    } else if (clickCount === 5 && Date.now() - clickTime! <= 1000) {
      setClickCount(0); // Resetta il conteggio dopo l'alert
      resetCodes();
    }
  }, [clickCount, clickTime]);

  const handleClick = () => {
    setClickTime(Date.now()); // Aggiorna il tempo dell'ultimo click
    setClickCount((prevCount) => prevCount + 1); // Incrementa il conteggio dei click
  };

  return (
    <Stack
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        p: 1,
      }}
      flexDirection="row"
      alignItems="center"
      gap={1}
    >
      <Typography sx={{ fontWeight: "500" }} onClick={handleClick}>
        Versione: {packageJson.version}
      </Typography>
      {isIlMioOttico && <Typography sx={{ fontWeight: "500" }}>IMO</Typography>}
      <Box flex={1} />
      <Typography sx={{ fontWeight: "500" }}>
        © {new Date().getFullYear()} Bludata Informatica S.r.l.
      </Typography>
      <img
        src={logo_bludata_50}
        style={{
          width: "94px",
          height: "25px",
        }}
        alt="bludata informatica"
      />
    </Stack>
  );
}

export default memo(FooterBludata);
