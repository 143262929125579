import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AppBackground from "../UI/AppBackground";
import BludataContainerBlock from "../UI/BludataContainerBlock";
import BludataBluTitle from "../UI/BludataBluTitle";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CachedIcon from "@mui/icons-material/Cached";
import { fetchListaClienti } from "./MainView";
import { ChangeEvent, useCallback, useState } from "react";
import { BluAsyncDialog } from "@bludata/components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import {
  setClienteFirma,
  setListaFirmaClienti,
  setSelectedView,
} from "../store/slices/RootSlice";
import BludataLoaderFullscreen from "../UI/BludataLoaderFullscreen";
import SearchIcon from "@mui/icons-material/Search";
import ClienteListItem from "./lista/ClienteListItem";
import { ClienteListaFirmaInterface } from "../interfaces/ClienteListaFirmaInterface";

function ListaFirmaClienti() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [ricerca, setRicerca] = useState<string>();
  const { listaFirmaClienti } = useSelector((state: RootState) => state.root);

  let listaRicerca = listaFirmaClienti;

  if (ricerca) {
    listaRicerca = listaRicerca.filter(
      (c) =>
        (c.Cognome + " " + c.Nome)
          .toLowerCase()
          .indexOf(ricerca.toLowerCase()) > -1
    );
  }

  const onRefreshTap = useCallback(() => {
    setLoading(true);
    fetchListaClienti()
      .then((response) => {
        dispatch(setListaFirmaClienti(response));
      })
      .catch(() => {
        BluAsyncDialog({
          title: "Attenzione",
          message: "Errore di connessione al server, riprovare più tardi.",
          hideDeclineButton: true,
          confimButton: "Chiudi",
          type: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  const onBackTap = useCallback(() => {
    dispatch(setSelectedView("home"));
  }, [dispatch]);

  const onClienteFirmaTap = useCallback(
    (cliente: ClienteListaFirmaInterface) => {
      dispatch(setClienteFirma(cliente));
      dispatch(setSelectedView("richiestafirma"));
    },
    [dispatch]
  );

  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", height: "100%", position: "relative" }}
    >
      <AppBackground />
      <BludataContainerBlock
        sx={{
          zIndex: 99,
          width: 600,
          height: 500,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack flexDirection="column" spacing={1} flex={1} sx={{ p: 1 }}>
          <Stack flexDirection="row" alignItems="center" gap={1}>
            {loading && <BludataLoaderFullscreen open />}
            <IconButton onClick={onBackTap} color="primary">
              <KeyboardArrowLeftIcon />
            </IconButton>
            <BludataBluTitle text="Lista dei clienti" sx={{ flex: 1 }} />
            <IconButton onClick={onRefreshTap} color="primary">
              <CachedIcon />
            </IconButton>
          </Stack>
          <Typography>
            Scegli il cliente a cui desideri far firmare il documento.
          </Typography>
          <TextField
            placeholder="Ricerca un cliente"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setRicerca(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Stack flex={1} sx={{ position: "relative" }}>
            <Stack
              flex={1}
              flexDirection="column"
              spacing={1}
              sx={{
                overflowY: "auto",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              {listaRicerca.map((cliente) => (
                <ClienteListItem
                  key={cliente.Codice}
                  cliente={cliente}
                  onClick={onClienteFirmaTap}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </BludataContainerBlock>
    </Stack>
  );
}

export default ListaFirmaClienti;
