import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import BludataBluTitle from "../../UI/BludataBluTitle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { BluAsyncDialog } from "@bludata/components";
import {
  setClienteFirma,
  setLinguaImpostata,
  setNuovaAnagrafica,
  setSelectedView,
} from "../../store/slices/RootSlice";
import { useForm, FormProvider } from "react-hook-form";
import DatiAnagrafici from "./DatiAnagrafici";
import Contatti from "./Contatti";
import Indirizzo from "./Indirizzo";
import linguaPrivacy from "../../static/linguaPrivacy.json";
import italian from "../../images/italian.png";
import english from "../../images/english.png";
import german from "../../images/german.png";
import { yupResolver } from "@hookform/resolvers/yup";
import { getFormSchema } from "../../static/yupFormSchema";
import FormDataInterface from "../../interfaces/FormDataInterface";

export const defaultFormValues: FormDataInterface = {
  titolo: "Sig.",
  cognome: "",
  nome: "",
  sesso: "M",
  dataNascita: null,
  luogoNascita: "",
  Stato: "IT",
  codiceFiscale: "",
  cellulare: "",
  telefono: "",
  telefono2: "",
  Email: "",
  Indirizzo: "",
  citta: "",
  CAP: "",
  CAP_straniero: "",
  Provincia: "",
  Provincia_straniera: "",
  Stato2: "IT",
  indirizzo2: "",
  citta2: "",
  CAP2: "",
  CAP2_straniero: "",
  Provincia2_straniera: "",
  Provincia2: "",
};

function FormInserimento() {
  const dispatch = useDispatch<AppDispatch>();
  const { linguaImpostata, infoCliente, nuovaAnagrafica } = useSelector(
    (state: RootState) => state.root
  );
  const { languageEnglish, languageItalian, languageGerman } = linguaPrivacy;

  let linguaggio = languageItalian;

  if (linguaImpostata === "EN") {
    linguaggio = languageEnglish;
  }
  if (linguaImpostata === "DE") {
    linguaggio = languageGerman;
  }

  const methods = useForm({
    defaultValues: nuovaAnagrafica || defaultFormValues,
    resolver: yupResolver(
      getFormSchema(infoCliente!.campiObbligatoriAnagrafica)
    ),
  });

  const onBackClick = async () => {
    const mrYes = await BluAsyncDialog({
      title: linguaggio.swal.attenzione,
      type: "warning",
      message: linguaggio.swal.backInserimento,
      confimButton: linguaggio.swal.continua,
      declineButton: linguaggio.swal.annulla,
    });

    if (mrYes) {
      dispatch(setNuovaAnagrafica(null));
      dispatch(setSelectedView("home"));
      dispatch(setLinguaImpostata("IT"));
    }
  };

  const onClearClick = async () => {
    const mrYes = await BluAsyncDialog({
      title: linguaggio.swal.attenzione,
      type: "warning",
      message: linguaggio.swal.clearDatas,
      confimButton: linguaggio.swal.continua,
      declineButton: linguaggio.swal.annulla,
    });

    if (mrYes) {
      methods.reset();
    }
  };

  const onSubmit = (data: any) => {
    let formData = data as any;
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(formData, key)) {
        if (typeof formData[key] === "string") {
          formData[key] = formData[key].trim();
        }
      }
    }

    dispatch(setNuovaAnagrafica(formData));
    dispatch(
      setClienteFirma({
        Nome: formData.nome,
        Cognome: formData.cognome,
        Cognome2: "",
        Indirizzo: formData.Indirizzo,
        Cap: formData.CAP || formData.CAP_straniero,
        Citta: formData.citta,
        Provincia: formData.Provincia,
      })
    );
    dispatch(setSelectedView("richiestafirma"));
  };

  const onFormError = (errors: any) => {
    console.log(errors);
    BluAsyncDialog({
      title: "Attenzione",
      message: "Compilare tutti i campi richiesti prima di continuare.",
      hideDeclineButton: true,
      confimButton: "Chiudi",
      type: "warning",
    });
  };

  return (
    <FormProvider {...methods}>
      <Stack
        flexDirection="column"
        sx={{ p: 2 }}
        flex={1}
        component={"form"}
        spacing={1}
        onSubmit={methods.handleSubmit(onSubmit, onFormError)}
      >
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <BludataBluTitle text={linguaggio.titoloForm} sx={{ flex: 1 }} />
          {(!!infoCliente?.["Nome privacy en"] ||
            !!infoCliente?.["Nome privacy de"]) && (
            <Select
              value={linguaImpostata}
              onChange={(e) => {
                dispatch(setLinguaImpostata(e.target.value));
              }}
            >
              <MenuItem value={"IT"}>
                <Stack flexDirection="row" gap={2} alignItems="center">
                  <img style={{ width: "24px" }} alt="ita" src={italian} />
                  <Typography sx={{ fontWeight: "bold" }}>Italiano</Typography>
                </Stack>
              </MenuItem>
              {!!infoCliente?.["Nome privacy en"] && (
                <MenuItem value={"EN"}>
                  <Stack flexDirection="row" gap={2} alignItems="center">
                    <img
                      style={{ width: "24px" }}
                      alt="english"
                      src={english}
                    />
                    <Typography sx={{ fontWeight: "bold" }}>English</Typography>
                  </Stack>
                </MenuItem>
              )}
              {!!infoCliente?.["Nome privacy de"] && (
                <MenuItem value={"DE"}>
                  <Stack flexDirection="row" gap={2} alignItems="center">
                    <img style={{ width: "24px" }} alt="english" src={german} />
                    <Typography sx={{ fontWeight: "bold" }}>Deutsch</Typography>
                  </Stack>
                </MenuItem>
              )}
            </Select>
          )}
        </Stack>
        <Stack flex={1} sx={{ position: "relative" }}>
          <Stack
            flexDirection="column"
            spacing={1}
            sx={{
              overflowY: "auto",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <DatiAnagrafici />
            <Indirizzo />
            <Contatti />
          </Stack>
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Button
            onClick={onBackClick}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            {linguaggio.bottoni.indietro}
          </Button>
          <Button
            onClick={onClearClick}
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
          >
            {linguaggio.bottoni.annulla}
          </Button>
          <Box flex={1} />
          <Button type="submit" startIcon={<ArrowForwardIcon />}>
            {linguaggio.bottoni.avanti}
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
}

export default FormInserimento;
