import { Stack, TextField, Typography } from "@mui/material";
import BludataContainerBlock from "../../UI/BludataContainerBlock";
import { Controller, useFormContext } from "react-hook-form";
import linguaPrivacy from "../../static/linguaPrivacy.json";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

function Contatti() {
  const { control } = useFormContext();
  const { linguaImpostata } = useSelector(
    (state: RootState) => state.root
  );
  const { languageEnglish, languageItalian, languageGerman } = linguaPrivacy;

  let linguaggio = languageItalian;

  if (linguaImpostata === "EN") {
    linguaggio = languageEnglish;
  }
  if (linguaImpostata === "DE") {
    linguaggio = languageGerman;
  }
  return (
    <BludataContainerBlock>
      <Stack flexDirection="column" spacing={1}>
        <Typography sx={{ fontWeight: "bold" }}>
          {linguaggio.sottotitoli.titoloContatti}
        </Typography>
        <Stack flexDirection="row" gap={1}>
          <Controller
            control={control}
            name="cellulare"
            render={({ field, formState:{errors} }) => (
              <TextField
                sx={{ flex: 1 }}
                autoComplete="off"
                error={!!errors[field.name]}
                label={linguaggio.inputField.cellulare}
                inputProps={{ maxlenght: 20 }}
                type="number"
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="telefono"
            render={({ field, formState:{errors} }) => (
              <TextField
                sx={{ flex: 1 }}
                autoComplete="off"
                error={!!errors[field.name]}
                inputProps={{ maxlenght: 25 }}
                label={linguaggio.inputField.telefono}
                type="number"
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="telefono2"
            render={({ field, formState:{errors} }) => (
              <TextField
                sx={{ flex: 1 }}
                autoComplete="off"
                error={!!errors[field.name]}
                label={linguaggio.inputField.telefono2}
                inputProps={{ maxlenght: 20 }}
                type="number"
                {...field}
              />
            )}
          />
        </Stack>
        <Controller
          control={control}
          name="Email"
          render={({ field, formState:{errors} }) => (
            <TextField
              sx={{ flex: 1 }}
              autoComplete="off"
              error={!!errors[field.name]}
              label="E-mail"
              inputProps={{ maxlenght: 50 }}
              {...field}
            />
          )}
        />
      </Stack>
    </BludataContainerBlock>
  );
}

export default Contatti;
