import { Box } from "@mui/material";
import { memo } from "react";
import background from "../images/background.jpg";

function AppBackground() {
  let backgroundImage = background;

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    />
  );
}

export default memo(AppBackground);
