import {
  Autocomplete,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import BludataContainerBlock from "../../UI/BludataContainerBlock";
import { Controller, useFormContext } from "react-hook-form";
import prov from "../../static/prov.json";
import { ChangeEvent, useEffect, useState } from "react";
import IndirizzoFatturazione from "./IndirizzoFatturazione";
import linguaPrivacy from "../../static/linguaPrivacy.json";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import stati from "../../static/stati.json";

function Indirizzo() {
  const { control, watch, register, setValue } = useFormContext();
  const [showAltroIndirizzo, setShowAltroIndirizzo] = useState(false);
  const stato = watch("Stato");
  const { linguaImpostata } = useSelector((state: RootState) => state.root);
  const { languageEnglish, languageItalian, languageGerman } = linguaPrivacy;

  let linguaggio = languageItalian;

  if (linguaImpostata === "EN") {
    linguaggio = languageEnglish;
  }
  if (linguaImpostata === "DE") {
    linguaggio = languageGerman;
  }

  useEffect(() => {
    register("Provincia");
    register("Stato", {
      value: "IT",
    });
  }, [register]);

  return (
    <BludataContainerBlock>
      <Stack flexDirection="column" spacing={1}>
        <Typography sx={{ fontWeight: "bold" }}>
          {linguaggio.sottotitoli.titoloIndFatt}
        </Typography>
        <Controller
          control={control}
          name="Indirizzo"
          render={({ field, formState: { errors } }) => (
            <TextField
              sx={{ flex: 1 }}
              error={!!errors[field.name]}
              autoComplete="off"
              inputProps={{ maxlenght: 40 }}
              label={linguaggio.inputField.indirizzo}
              {...field}
            />
          )}
        />
        <Stack flexDirection="row" gap={1}>
          <Controller
            control={control}
            name="citta"
            render={({ field, formState: { errors } }) => (
              <TextField
                sx={{ flex: 1 }}
                error={!!errors[field.name]}
                inputProps={{ maxlenght: 45 }}
                autoComplete="off"
                label={linguaggio.inputField.citta}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name={stato === "IT" ? "CAP" : "CAP_straniero"}
            render={({ field, formState: { errors } }) => (
              <TextField
                sx={{ width: 100 }}
                type={stato === "IT" ? "number" : "text"}
                error={!!errors[field.name]}
                inputProps={{ maxlenght: stato === "IT" ? 5 : 9 }}
                autoComplete="off"
                label={linguaggio.inputField.cap}
                {...field}
              />
            )}
          />
          {stato === "IT" && (
            <Autocomplete
              options={prov.map((p) => {
                return { ...p, label: `${p.codice} - ${p.nome}` };
              })}
              sx={{ width: 160 }}
              onChange={(e, newValue) => {
                setValue("Provincia", newValue?.codice || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={linguaggio.inputField.provincia}
                />
              )}
            />
          )}
          {stato !== "IT" && (
            <Controller
              control={control}
              name="Provincia_straniera"
              render={({ field, formState: { errors } }) => (
                <TextField
                  sx={{ width: 150 }}
                  error={!!errors[field.name]}
                  autoComplete="off"
                  inputProps={{ maxlenght: 50 }}
                  label={linguaggio.inputField.provincia}
                  {...field}
                />
              )}
            />
          )}
          <Autocomplete
            options={stati.map((p) => {
              return { ...p, label: `${p.stato} - ${p.name}` };
            })}
            disableClearable
            defaultValue={stati
              .map((p) => {
                return { ...p, label: `${p.stato} - ${p.name}` };
              })
              .find((s) => s.stato === "IT")}
            sx={{ width: 140 }}
            onChange={(e, newValue) => {
              setValue("Stato", newValue?.stato);
            }}
            renderInput={(params) => (
              <TextField {...params} label={linguaggio.inputField.stato} />
            )}
          />
        </Stack>
      </Stack>
      <FormControlLabel
        control={
          <Switch
            checked={showAltroIndirizzo}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setShowAltroIndirizzo(e.target.checked);
            }}
          />
        }
        label={linguaggio.bottoni.altroIndirizzo}
      />
      <>{showAltroIndirizzo && <IndirizzoFatturazione />}</>
    </BludataContainerBlock>
  );
}

export default Indirizzo;
