import { BluAsyncDialog } from "@bludata/components";
import {
  Alert,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { useState } from "react";
import {
  estraiParametroX,
  estraiUrlBase,
  regexDATAXIMO,
  regexFOCUSBLUDATACOM,
} from "../utils/QRFunctions";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface Props {
  annullaAttivazione: () => void;
}

function ScannerizzazioneQR(props: Props) {
  const [showErrorDialog, setShowErrorDialog] = useState("");
  const [loadingActivation, setLoadingActivation] = useState(false);

  const onAnnullaAttivazioneClick = () => {
    props.annullaAttivazione();
  };

  const onQrScannerError = () => {
    setShowErrorDialog("Errore durante la lettura del codice QR.");
  };

  const networkErrorDuringActivation = () => {
    localStorage.removeItem("bluSign_url_ipad");
    setLoadingActivation(false);
    onAnnullaAttivazioneClick();
    BluAsyncDialog({
      title: "Attenzione",
      message:
        "Errore di connessione al server durante la procedura di attivazione.",
      confimButton: "Riavvia",
      hideDeclineButton: true,
      type: "error",
      onConfirmClick: () => {
        window.location.reload();
      },
    });
  };

  const onQrScannerDecodeSuccess = (scannedUrl: string) => {
    const isImoUrlScanned = regexDATAXIMO.test(scannedUrl);
    const isGenericUrlScanned = regexFOCUSBLUDATACOM.test(scannedUrl);
    let codiceAttivazione = estraiParametroX(scannedUrl);
    let urlBaseScanned = estraiUrlBase(scannedUrl);

    if ((isImoUrlScanned || isGenericUrlScanned) && codiceAttivazione) {
      setLoadingActivation(true);
      localStorage.bluSign_url_ipad = urlBaseScanned;

      axios({
        method: "post",
        url: "/init",
        data: {
          idcode: codiceAttivazione,
        },
      })
        .then((response) => {
          const codiceAttivazioneDefinitivo = response.data;

          if (typeof codiceAttivazioneDefinitivo === "string") {
            localStorage.codiceAzienda_ipad = codiceAttivazioneDefinitivo;
            setLoadingActivation(false);
            window.location.reload();
          } else {
            networkErrorDuringActivation();
          }
        })
        .catch(networkErrorDuringActivation);
    } else {
      setShowErrorDialog(
        "Il codice QR inquadrato non è valido per attivare Blu Sign"
      );
    }
  };

  return (
    <>
      <Typography>
        Inquadra il codice QR presente in FOCUS 10 per effettuare l'attivazione.
      </Typography>
      <Stack flexDirection="column" spacing={1}>
        {loadingActivation && (
          <Stack flexDirection="row" gap={2} alignItems="center">
            <CircularProgress variant="indeterminate" />
            <Typography>Attivazione in corso</Typography>
          </Stack>
        )}
        {!loadingActivation && (
          <>
            <QrScanner
              onDecode={onQrScannerDecodeSuccess}
              onError={onQrScannerError}
              stopDecoding={loadingActivation}
              scanDelay={2000}
              containerStyle={{
                borderRadius: "10px",
              }}
            />
            {!!showErrorDialog && (
              <Alert title="Attenzione" severity="error">
                {showErrorDialog}
              </Alert>
            )}
            <Button
              variant="outlined"
              disableElevation
              startIcon={<ArrowBackIcon />}
              onClick={onAnnullaAttivazioneClick}
            >
              Annulla attivazione
            </Button>
          </>
        )}
      </Stack>
    </>
  );
}

export default ScannerizzazioneQR;
