import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import InfoClienteInterface from "../../interfaces/InfoClienteInterface";
import { ClienteListaFirmaInterface } from "../../interfaces/ClienteListaFirmaInterface";
import TestiDefaultInterface from "../../interfaces/TestiDefaultInterface";
import FormDataInterface from "../../interfaces/FormDataInterface";

export interface RootSliceState {
  infoCliente?: InfoClienteInterface;
  selectedView: string;
  linguaImpostata: string;
  listaFirmaClienti: ClienteListaFirmaInterface[];
  clienteFirma?: ClienteListaFirmaInterface;
  testiDefault?: TestiDefaultInterface;
  nuovaAnagrafica?: FormDataInterface | null;
}

const initialState: RootSliceState = {
  selectedView: "home",
  linguaImpostata: localStorage.getItem("lastLanguageSelected") || "IT",
  listaFirmaClienti: [],
};

export const RootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setInfoCliente(
      state: RootSliceState,
      action: PayloadAction<InfoClienteInterface>
    ) {
      state.infoCliente = action.payload;
    },
    setSelectedView(state: RootSliceState, action: PayloadAction<string>) {
      state.selectedView = action.payload;
    },
    setLinguaImpostata(state: RootSliceState, action: PayloadAction<string>) {
      localStorage.setItem("lastLanguageSelected", action.payload);
      state.linguaImpostata = action.payload;
    },
    setListaFirmaClienti(
      state: RootSliceState,
      action: PayloadAction<ClienteListaFirmaInterface[]>
    ) {
      state.listaFirmaClienti = action.payload;
    },
    setClienteFirma(
      state: RootSliceState,
      action: PayloadAction<ClienteListaFirmaInterface>
    ) {
      state.clienteFirma = action.payload;
    },
    setTestiDefault(
      state: RootSliceState,
      action: PayloadAction<TestiDefaultInterface>
    ) {
      state.testiDefault = action.payload;
    },
    setNuovaAnagrafica(
      state: RootSliceState,
      action: PayloadAction<FormDataInterface | null>
    ) {
      state.nuovaAnagrafica = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setInfoCliente,
  setSelectedView,
  setLinguaImpostata,
  setListaFirmaClienti,
  setClienteFirma,
  setTestiDefault,
  setNuovaAnagrafica,
} = RootSlice.actions;

export default RootSlice.reducer;
