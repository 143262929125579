const getCampoObbligatorio = (
  nomeCampo: string,
  listaCampiObbligatori: string
) => {
  let campiObbligatori = JSON.parse(listaCampiObbligatori || "[]");
  const oggettoMappatura: any = {
    cognome: "cognome",
    nome: "nome",
    sesso: "sesso",
    dataNascita: "data nascita",
    luogoNascita: "luogo nascita",
    cellulare: "telefonino gsm",
    telefono: "telefono",
    telefono2: "telefono2",
    Email: "e mail",
    Indirizzo: "indirizzo",
    citta: "citta",
    CAP: "cap",
    Provincia: "provincia",
  };

  if (!oggettoMappatura.hasOwnProperty(nomeCampo)) {
    return false;
  }

  let nomeCampoMappato = oggettoMappatura[nomeCampo];
  if (nomeCampoMappato && campiObbligatori.indexOf(nomeCampoMappato) >= 0)
    return true;
  return false;
};

export default getCampoObbligatorio;
